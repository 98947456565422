import './Facility.css';
import { List, TextInput, Checkbox } from '@mantine/core';
import { useState } from 'react';
import { noteChanges } from '../pages/FacilityManagement';

const Facility = props => {
  const [name, setName] = useState(props.name);
  const [enabled, setEnabled] = useState(props.enabled);

  const changed = name !== props.name || enabled !== props.enabled;
  noteChanges(
    {
      id: props.id,
      name: name,
      enabled: enabled,
    },
    changed
  );

  return (
    <div className={`Facility ${changed ? 'changed-facility' : ''}`}>
      <List.Item
        className='facility-item'
        icon={
          <Checkbox
            checked={enabled}
            onChange={e => setEnabled(e.target.checked)}
          />
        }
      >
        <TextInput
          required
          placeholder='Facility name'
          name='name'
          value={name}
          onChange={e => {
            const { value } = e.target;
            setName(value);
          }}
        />
      </List.Item>
    </div>
  );
};

export default Facility;
