import { get } from 'axios';
import { getApiConfig, getApiUri } from '../Utilities';

export const loadFacilities = (excludeDisabled, callback) => {
  get(
    getApiUri(`facilities?excludeDisabled=${excludeDisabled}`),
    getApiConfig()
  ).then(response => {
    callback(response.data.facilities);
  });
};

export const loadSpaces = (facilityId, callback) => {
  get(getApiUri(`spaces/${facilityId}`), getApiConfig()).then(response => {
    callback(response.data.spaces);
  });
};
