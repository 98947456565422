import './FacilityManagement.css';
import { useEffect, useState, useContext } from 'react';
import { Stack, List, Button, Group } from '@mantine/core';
import { loadFacilities } from '../requests/SharedRequests';
import { updateFacilities } from '../requests/FacilityManagementRequests';
import { SharedContext } from '../App';
import Facility from '../components/Facility';
import { notifySuccessWithChangeCount } from '../Utilities';
import useAuthenticationCheck from '../hooks/useAuthenticationCheck';
import { useNavigate } from 'react-router-dom';

const submitUpdates = () => {
  updateFacilities(changes, numberOfChanges => {
    notifySuccessWithChangeCount('update', numberOfChanges);
  });
};

let changes = [];
export const noteChanges = (facility, changed) => {
  changes = changes.filter(change => change.id !== facility.id);

  if (changed) {
    changes.push(facility);
  }
};

const FacilityManagement = () => {
  useAuthenticationCheck();

  const navigate = useNavigate();
  const { setPageAlignItems } = useContext(SharedContext);

  const [facilities, setFacilities] = useState(null);

  useEffect(() => {
    setPageAlignItems('flex-start');

    if (facilities == null) {
      loadFacilities(false, loadedFacilities => {
        setFacilities(loadedFacilities);
      });
    }

    return () => setPageAlignItems('center');
  }, [facilities, setPageAlignItems]);

  const buttons = (
    <Group grow>
      <Button onClick={() => navigate('/space-management')} variant='outline'>
        Odustani
      </Button>
      <Button
        onClick={() => submitUpdates(facilities)}
        // disabled={!changes.length}
      >
        Ažuriraj
      </Button>
    </Group>
  );

  return (
    <div className='FacilityManagement'>
      {facilities && (
        <Stack>
          {buttons}
          <List spacing='xs' size='sm' center style={{ margin: '0' }}>
            {facilities.map(facility => (
              <Facility key={facility.id} {...facility} />
            ))}
          </List>
          {buttons}
        </Stack>
      )}
    </div>
  );
};

export default FacilityManagement;
