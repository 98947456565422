import './Footer.css';
import moment from "moment";

const Footer = () => (
    <footer>
        <div className="copyright-note">
            &copy; {moment().year()} Tapoint. All rights reserved.
        </div>

        <img src="https://tags.tapoint.net/Logos/tapoint-logo.png" alt="Tapoint Logo"
             className="tapoint-logo"/>
    </footer>
);

export default Footer;