import axios, { get, post, put } from 'axios';
import {
  getApiConfig,
  getApiUri,
  getNameFromLocalizedValues,
} from '../Utilities';

export const loadDataForFacility = (facilityId, callback) => {
  get(getApiUri(`items/pdfs/${facilityId}`), getApiConfig()).then(response => {
    callback(
      response.data.pdfs,
      response.data.categories,
      response.data.languages
    );
  });
};

export const loadSpaceTypes = callback => {
  get(getApiUri('space-types'), getApiConfig()).then(response => {
    callback(response.data.spaceTypes);
  });
};

export const addPdfByData = (
  categoryId,
  spaceType,
  order,
  fileName,
  data,
  localizedValues,
  callback
) => {
  const name = getNameFromLocalizedValues(localizedValues);
  post(
    getApiUri(`items/pdfs/data`),
    {
      name: name,
      categoryId: categoryId,
      spaceType: spaceType,
      order: order,
      fileName: fileName || `${name}.pdf`,
      data: data,
      localizedValues: localizedValues,
    },
    getApiConfig()
  ).then(response => {
    callback(response.data.pdfId, response.data.location);
  });
};

export const addPdfByLocation = (
  categoryId,
  spaceType,
  order,
  fileName,
  location,
  localizedValues,
  callback
) => {
  const name = getNameFromLocalizedValues(localizedValues);
  post(
    getApiUri(`items/pdfs/location`),
    {
      name: name,
      categoryId: categoryId,
      spaceType: spaceType,
      order: order,
      fileName: fileName || `${name}.pdf`,
      location: location,
      downloadAndStore: true,
      localizedValues: localizedValues,
    },
    getApiConfig()
  ).then(response => {
    callback(response.data.pdfId, response.data.location);
  });
};

export const updatePdfBasicData = (
  id,
  categoryId,
  spaceType,
  order,
  localizedValues,
  callback
) => {
  const name = getNameFromLocalizedValues(localizedValues);
  put(
    getApiUri(`items/pdfs/${id}`),
    {
      name: name,
      categoryId: categoryId,
      spaceType: spaceType,
      order: order,
      localizedValues: localizedValues,
    },
    getApiConfig()
  ).then(response => {
    callback(response.data.updatedPdf);
  });
};

export const updatePdfByData = (
  id,
  data,
  fileName,
  localizedValues,
  callback
) => {
  const name = getNameFromLocalizedValues(localizedValues);
  put(
    getApiUri(`items/pdfs/${id}/data`),
    {
      fileName: fileName || `${name}.pdf`,
      data: data,
    },
    getApiConfig()
  ).then(response => {
    callback(response.data.updatedPdf);
  });
};

export const updatePdfByLocation = (
  id,
  location,
  fileName,
  localizedValues,
  callback
) => {
  const name = getNameFromLocalizedValues(localizedValues);
  put(
    getApiUri(`items/pdfs/${id}/location`),
    {
      fileName: fileName || `${name}.pdf`,
      location: location,
      downloadAndStore: true,
    },
    getApiConfig()
  ).then(response => {
    callback(response.data.updatedPdf);
  });
};

export const deletePdf = (id, callback) => {
  axios
    .delete(getApiUri(`items/pdfs/${id}`), getApiConfig())
    .then(() => callback());
};

// =========
// Utilities
// =========
export const createNewPdf = (id, location, localizedValues, formValues) => {
  return {
    id: id,
    value: location,
    localizedValues: localizedValues,
    parentId: formValues.categoryId,
    name: formValues.name,
    spaceType: formValues.spaceType,
    order: formValues.order,
  };
};
