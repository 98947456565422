import './Header.css';

import Centered from './Centered';
import { useNavigate } from 'react-router-dom';
import { SharedContext } from '../App';
import { useContext } from 'react';

const Header = ({ title }) => {
  const { setAppTitle, setRoutes } = useContext(SharedContext);

  const navigate = useNavigate();
  const canLogOut = !!localStorage.getItem('token');

  return (
    <header className='container'>
      <img
        src='https://porestina.info/wp-content/uploads/2018/10/VALAMAR-NOVI-LOGO.png'
        alt='Logo'
        className='logo'
      />

      {canLogOut ? (
        <Centered>
          <div className='facility-name'>{title}</div>
        </Centered>
      ) : (
        <></>
      )}

      {canLogOut ? (
        <Centered>
          <button
            className='unset-button'
            onClick={() => {
              localStorage.removeItem('facilityName');
              localStorage.removeItem('token');
              setAppTitle('');
              setRoutes([]);
              navigate('/login');
            }}
          >
            <span
              className='oi oi-power-standby log-out-button-icon'
              aria-hidden='true'
            ></span>
          </button>
        </Centered>
      ) : (
        <></>
      )}
    </header>
  );
};

export default Header;
