import { get, put } from 'axios';
import { getApiConfig, getApiUri } from '../Utilities';

export const getDataForIdentifier = (identifier, callback) => {
  get(getApiUri(`identifiers?identifier=${identifier}`), getApiConfig()).then(
    response => {
      callback(response.data.spaceId, response.data.facilityId);
    }
  );
};

export const updateDataForIdentifier = (identifier, spaceId, callback) => {
  put(
    getApiUri('identifiers'),
    {
      identifier: identifier,
      spaceId: spaceId,
    },
    getApiConfig()
  ).then(response => {
    callback(response.data.numberOfChanges);
  });
};
