import { Upload, X, FileText } from 'tabler-icons-react';

const ImageUploadIcon = ({ status, ...props }) => {
  if (status.accepted) {
    return <Upload {...props} />;
  }

  if (status.rejected) {
    return <X {...props} />;
  }

  return <FileText {...props} />;
};

export default ImageUploadIcon;
