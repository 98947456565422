import './Order.css';
import moment from 'moment';
import {
  Badge,
  Group,
  Title,
  Text,
  Card,
  List,
  ThemeIcon,
  Button,
} from '@mantine/core';
import { useState } from 'react';

const classes = 'Order card';
const classesWithBlinking = `${classes} blink`;

const Order = ({
  id,
  status,
  space,
  time,
  items,
  setOrderStatus,
  orderStatusToSet,
}) => {
  const [classList, setClassList] = useState(
    status !== 'Received' ? classes : classesWithBlinking
  );

  return (
    <div
      className={classList}
      onTouchStart={() => {
        setClassList(classes);
      }}
      onMouseEnter={() => {
        setClassList(classes);
      }}
    >
      <Card key={id} shadow='sm' p='lg'>
        <Group position='apart' style={{ marginBottom: 5, marginTop: 3 }}>
          <Title order={3}>{space}</Title>
          <Badge variant='outline' radius='xs' size='lg'>
            {moment(time).format('LTS')}
          </Badge>
        </Group>

        <List spacing='xs' size='sm' center style={{ margin: '10px 0 20px' }}>
          {items.map(({ id, name, value, count }) => (
            <List.Item
              key={id}
              icon={
                <ThemeIcon size={24} radius='xl'>
                  <Text weight='bold'>{count}</Text>
                </ThemeIcon>
              }
            >
              <Text>
                {name} ({value})
              </Text>
            </List.Item>
          ))}
        </List>

        {setOrderStatus && orderStatusToSet ? (
          <Button
            fullWidth
            onClick={() => setOrderStatus(id, orderStatusToSet)}
          >
            {orderStatusToSet}
          </Button>
        ) : (
          <></>
        )}
      </Card>
    </div>
  );
};

export default Order;
