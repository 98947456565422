import './IdentifierManagement.css';
import { useEffect, useState } from 'react';
import {
  Button,
  ActionIcon,
  Group,
  Modal,
  TextInput,
  Select,
} from '@mantine/core';
import { BuildingSkyscraper, Bed, Id, Clipboard } from 'tabler-icons-react';
import {
  getDataForIdentifier,
  updateDataForIdentifier,
} from '../requests/IdentifierManagementRequests';
import { loadFacilities } from '../requests/SharedRequests';
import {
  getSpacesForFacility,
  notifySuccessWithChangeCount,
} from '../Utilities';
import useAuthenticationCheck from '../hooks/useAuthenticationCheck';

const IdentifierManagement = () => {
  useAuthenticationCheck();

  const [facilities, setFacilities] = useState(null);
  const [spaces, setSpaces] = useState(null);

  const [showWarningModal, setShowWarningModal] = useState(false);

  const [identifier, setIdentifier] = useState('');
  const [identifierError, setIdentifierError] = useState(null);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [selectedSpace, setSelectedSpace] = useState(null);

  const checkIdentifier = value => {
    getDataForIdentifier(value, (spaceId, facilityId) => {
      if (!(spaceId && facilityId)) return;

      getSpacesForFacility(facilityId, spaces => {
        setSpaces(spaces);
        setSelectedFacility(`${facilityId}`);
        setSelectedSpace(`${spaceId}`);
      });
    });
  };

  useEffect(() => {
    const onFocus = async () => {
      const clipboardContent = await navigator.clipboard.readText();
      if (clipboardContent === identifier) return;

      if (clipboardContent.length < 10) return;

      if (
        clipboardContent.includes('QR_') ||
        clipboardContent.replace(':', '').length === 14
      ) {
        setIdentifier(clipboardContent);
        checkIdentifier(clipboardContent);
      }
    };

    window.addEventListener('focus', onFocus);

    if (facilities == null) {
      loadFacilities(true, loadedFacilities => {
        setFacilities(
          loadedFacilities.filter(facility => facility && facility.enabled)
        );
      });
    }

    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, [facilities, identifier]);

  return (
    <div className='IdentifierManagement'>
      <TextInput
        icon={<Id />}
        label='QR / NFC'
        placeholder='QR_123456789012345678901 ili 12345678901234'
        error={identifierError}
        name='identifier'
        value={identifier}
        onBlur={e => {
          const { value } = e.target;
          if (value.length > 10) {
            checkIdentifier(value);
          }
        }}
        onChange={e => {
          const { value } = e.target;
          setIdentifier(value);
          setIdentifierError(
            identifier?.length > 0 ? '' : 'Morate unijeti QR ili NFC'
          );
        }}
        rightSection={
          <ActionIcon
            onClick={async () => {
              const clipboardContent = await navigator.clipboard.readText();
              setIdentifier(clipboardContent);
              checkIdentifier(clipboardContent);
            }}
          >
            <Clipboard />
          </ActionIcon>
        }
      />

      {facilities && (
        <Select
          searchable
          clearable
          nothingFound='Nema kampanje s tim imenom'
          icon={<BuildingSkyscraper />}
          label='Kampanja'
          placeholder='Pretražite kampanje'
          maxDropdownHeight={650}
          data={facilities.map(facility => {
            return {
              value: `${facility.id}`,
              label: facility.name,
            };
          })}
          value={selectedFacility}
          name='facilitySearch'
          onChange={e => {
            if (!e) {
              setSelectedFacility(null);
              return;
            }
            getSpacesForFacility(e, spaces => {
              setSpaces(spaces);
              setSelectedFacility(e);
            });
          }}
        />
      )}

      {spaces && (
        <Select
          searchable
          clearable
          nothingFound='Nema labele s tim imenom'
          icon={<Bed />}
          label='Labela'
          placeholder='Pretražite labele'
          maxDropdownHeight={650}
          data={spaces.map(space => {
            return {
              value: `${space.id}`,
              label: space.name,
            };
          })}
          value={selectedSpace}
          name='spaceSearch'
          onChange={setSelectedSpace}
        />
      )}

      <Button
        onClick={() => {
          if (!selectedSpace) {
            setShowWarningModal(true);
            return;
          }

          updateDataForIdentifier(
            identifier,
            selectedSpace,
            numberOfChanges => {
              notifySuccessWithChangeCount('update', numberOfChanges);
            }
          );
        }}
        fullWidth
        style={{ marginTop: 15 }}
      >
        Spremi
      </Button>

      <Modal
        centered
        transition='slide-left'
        opened={showWarningModal}
        onClose={() => setShowWarningModal(false)}
        title={`Jeste li sigurni da želite ukloniti kampanju i labelu za '${identifier}'?`}
      >
        <Group>
          <Button
            onClick={() => {
              updateDataForIdentifier(identifier, null, numberOfChanges => {
                notifySuccessWithChangeCount('update', numberOfChanges);
              });
              setShowWarningModal(false);
            }}
          >
            Da
          </Button>
          <Button variant='outline' onClick={() => setShowWarningModal(false)}>
            Ne
          </Button>
        </Group>
      </Modal>
    </div>
  );
};

export default IdentifierManagement;
