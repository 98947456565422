import './Navigation.css';
import { NavLink } from 'react-router-dom';
import { useContext } from 'react';
import { SharedContext } from '../App';
import {
  BuildingSkyscraper,
  ChartBar,
  FileText,
  Id,
  ToolsKitchen2,
} from 'tabler-icons-react';

const getIcon = route => {
  switch (route) {
    case '/orders':
      return <ToolsKitchen2 />;
    case '/statistics':
      return <ChartBar />;
    case '/pdf-management':
      return <FileText />;
    case '/identifier-management':
      return <Id />;
    case '/space-management':
      return <BuildingSkyscraper />;
    default:
      throw Error(`Unknown route: ${route}`);
  }
};

const Navigation = () => {
  const { routes } = useContext(SharedContext);

  return (
    routes &&
    routes.length > 1 && (
      <nav className='navigation'>
        {routes.map(route => (
          <NavLink key={route.path} className='navigation-link' to={route.path}>
            {getIcon(route.path)} {route.displayText.toUpperCase()}
          </NavLink>
        ))}
      </nav>
    )
  );
};

export default Navigation;
