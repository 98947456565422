import './LinkWithIcon.css';
import { Text } from '@mantine/core';

const LinkWithIcon = ({ icon, text, url, openInNewTab }) => {
  return (
    <div
      className='LinkWithIcon'
      onClick={() => window.open(url, openInNewTab ? '_blank' : null)}
    >
      {icon}
      <Text className='LinkWithIconText'>{text}</Text>
    </div>
  );
};

export default LinkWithIcon;
