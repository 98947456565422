import './Orders.css';
import { Accordion, ThemeIcon } from '@mantine/core';
import { ActivityHeartbeat, Checklist } from 'tabler-icons-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { get, post } from 'axios';
import { getApiConfig, getApiUri } from '../Utilities';
import OrderGroup from '../components/OrderGroup';
import useAuthenticationCheck from '../hooks/useAuthenticationCheck';

const Orders = () => {
  useAuthenticationCheck();

  const [orders, setOrders] = useState(null);
  const [lastFetchTime, setLastFetchTime] = useState(null);
  const [lastFetchDay, setLastFetchDay] = useState(null);

  const changeStatus = (orderId, newStatus, callback) => {
    post(
      getApiUri('orders/change-status'),
      {
        orderId: orderId,
        newStatus: newStatus,
      },
      getApiConfig()
    ).then(() => {
      callback();
    });
  };

  const loadData = (theLastFetchTime, callback) => {
    get(
      getApiUri(
        `orders/table${
          theLastFetchTime ? '?lastFetchTime=' + theLastFetchTime : ''
        }`
      ),
      getApiConfig()
    ).then(response => {
      setLastFetchTime(response.data.lastFetchTime);
      callback(response.data.orders);
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      let isNewDay;
      if (!lastFetchTime) {
        isNewDay = true;
      } else {
        // Adjust so that backend does new day fetch when it is also new day there
        const dateTime = moment(lastFetchTime).subtract({
          hours: 3,
          minutes: 6,
        });
        const day = dateTime.format('YYYY-MM-DD');
        if (lastFetchDay && lastFetchDay === day) {
          isNewDay = false;
        } else {
          isNewDay = true;
          setLastFetchDay(day);
        }
      }

      if (isNewDay) {
        loadData(null, setOrders);
      } else {
        loadData(lastFetchTime, items => {
          if (items.length > 0) {
            setOrders(orders.concat(items));
          }
        });
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [lastFetchTime, lastFetchDay, orders]);

  if (!orders?.length) {
    return (
      <div className='Orders text-center'>
        There are currently no orders for today.
      </div>
    );
  }

  const setOrderStatus = (orderId, newStatus) => {
    changeStatus(orderId, newStatus, () => {
      const targetOrder = orders.find(x => x.id === orderId);
      targetOrder.status = newStatus;
      setOrders(orders);
    });
  };

  const received = orders.filter(x => x.status === 'Received');
  const done = orders.filter(x => x.status === 'Done');

  return (
    <div className='Orders'>
      <Accordion initialItem={0}>
        <Accordion.Item
          icon={
            <ThemeIcon color='indigo' variant='light' radius='xl'>
              <ActivityHeartbeat size={20} />
            </ThemeIcon>
          }
          label='Received'
        >
          <OrderGroup
            orders={received}
            setOrderStatus={setOrderStatus}
            orderStatusToSet='Done'
          />
        </Accordion.Item>

        <Accordion.Item
          icon={
            <ThemeIcon color='green' variant='light' radius='xl'>
              <Checklist size={20} />
            </ThemeIcon>
          }
          label='Done'
        >
          <OrderGroup orders={done} />
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Orders;
