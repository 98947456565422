import './App.css';
import { createContext, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
// import Statistics from "./pages/Statistics";
import PdfManagement from './pages/PdfManagement';
import Login from './pages/Login';
import Footer from './components/Footer';
import Header from './components/Header';
import Navigation from './components/Navigation';
import Orders from './pages/Orders';
import IdentifierManagement from './pages/IdentifierManagement';
import SpaceManagement from './pages/SpaceManagement';
import FacilityManagement from './pages/FacilityManagement';

export const SharedContext = createContext();

const App = () => {
  const [title, setAppTitle] = useState('');
  const [routes, setRoutes] = useState([]);
  const [pageJustifyContent, setPageJustifyContent] = useState('center');
  const [pageAlignItems, setPageAlignItems] = useState('center');
  return (
    <SharedContext.Provider
      value={{
        routes,
        setRoutes,
        title,
        setAppTitle,
        setPageJustifyContent,
        setPageAlignItems,
      }}
    >
      <div className='App'>
        <Header title={title} />

        <div className='header-underline'></div>

        <main className='container'>
          <Navigation />

          <div
            className='content-container'
            style={{
              justifyContent: pageJustifyContent,
              alignItems: pageAlignItems,
            }}
          >
            <article className='content'>
              <Routes>
                {/*<Route path="statistics" element={<Statistics />} />*/}
                <Route path='pdf-management' element={<PdfManagement />} />
                <Route path='orders' element={<Orders />} />
                <Route
                  path='facility-management'
                  element={<FacilityManagement />}
                />
                <Route path='space-management' element={<SpaceManagement />} />
                <Route
                  path='identifier-management'
                  element={<IdentifierManagement />}
                />
                <Route path='login' element={<Login />} />

                <Route path='/' element={<Navigate to='login' />} />
              </Routes>
            </article>
          </div>
        </main>

        <Footer />
      </div>
    </SharedContext.Provider>
  );
};

export default App;
