import { post } from 'axios';
import { getApiUri } from '../Utilities';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const checkToken = (onGotUserData, onFailedValidation) => {
  const token = localStorage.getItem('token');
  if (!token) {
    onFailedValidation?.();
    return;
  }

  post(getApiUri('users/get-user-data'), {
    token: token,
  })
    .then(response => {
      onGotUserData?.(response);
    })
    .catch(() => {
      onFailedValidation?.();
    });
};

const useAuthenticationCheck = () => {
  const navigate = useNavigate();

  useEffect(() => {
    checkToken(null, () => navigate('/login'));
  }, [navigate]);
};

export default useAuthenticationCheck;
