import { Buffer } from 'buffer';
import { showNotification } from '@mantine/notifications';
import { loadSpaces } from './requests/SharedRequests';

// const domain = 'localhost:6032';
const domain = 'api.tapoint.net';

const baseUrl = `https://${domain}/api/v1/`;

const fallbackCultures = ['hr', 'en'];

export const getApiUri = path => new URL(path, baseUrl).href;

export const getApiConfig = () => {
  return {
    headers: {
      Authorization: localStorage.getItem('token'),
    },
  };
};

export const getItemDisplayValue = (itemViewModel, culture = 'hr') => {
  if (!itemViewModel?.localizedValues) return '';

  return (
    getNameFromLocalizedValues(itemViewModel.localizedValues, culture) ||
    itemViewModel.name
  );
};

export const getNameFromLocalizedValues = (localizedValues, culture) => {
  if (!localizedValues?.length) return '';

  const validValues = localizedValues.filter(value => !!value.value);

  if (!validValues.length) return '';

  if (validValues.length === 1) {
    return validValues[0]?.value;
  }

  let localizedValue;

  const cultureFromStorage = localStorage.getItem('culture');
  const targetCulture = cultureFromStorage || culture;

  if (targetCulture) {
    localizedValue = validValues.find(value => value.culture === targetCulture);
  }

  if (!localizedValue?.value) {
    for (let culture of fallbackCultures) {
      localizedValue = validValues.find(value => value.culture === culture);
      if (localizedValue?.value) {
        break;
      }
    }
  }

  if (!localizedValue?.value) {
    localizedValue = validValues[0];
  }

  return localizedValue?.value;
};

export const readFileAsBase64String = (file, onRead) => {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);
  reader.onload = () => {
    const base64Data = Buffer.from(reader.result).toString('base64');
    onRead(base64Data);
  };
};

export const getLinkForIdentifier = (identifier, statistics = false) =>
  `https://tags.tapoint.net/data/tag?m=${identifier}${
    statistics ? '' : '&statistics=false'
  }`;

export const updateFormFieldFromEvent = (
  formValues,
  setFormValues,
  changeEventArguments
) => {
  const { name, value } = changeEventArguments.target;
  if (!name) {
    alert('You forgot to add a name to the form control');
    return;
  }
  updateFormField(formValues, setFormValues, name, value);
};

export const updateFormField = (formValues, setFormValues, name, value) => {
  setFormValues({ ...formValues, [name]: value });
};

export const extractNumberFromString = string => {
  const minus = string.startsWith('-') ? '-' : '';
  const onlyNumbers = string.replace(/\D/g, '');

  let index = -1;
  for (let i = 0; i < onlyNumbers.length; i++) {
    if (onlyNumbers[i] !== '0') {
      index = i;
      break;
    }
  }

  return `${minus}${onlyNumbers.substring(index)}`;
};

export const notifySuccessWithChangeCount = (operation, numberOfChanges) => {
  let titleVerb, messageVerb;
  switch (operation) {
    case 'add':
      titleVerb = 'Dodavanje';
      messageVerb = 'dodalo';
      break;

    case 'update':
      titleVerb = 'Ažuriranje';
      messageVerb = 'ažuriralo';
      break;

    case 'delete':
      titleVerb = 'Brisanje';
      messageVerb = 'obrisalo';
      break;

    default:
      throw Error('Unknown operation (expected add, update or delete)');
  }
  showNotification({
    color: 'green',
    title: `${titleVerb} uspješno`,
    message: `Uspješno se ${messageVerb} ${numberOfChanges} podataka`,
  });
};

const spacesByFacility = {};
export const getSpacesForFacility = (facilityId, callback) => {
  const cached = spacesByFacility[facilityId];

  if (!cached) {
    loadSpaces(facilityId, spaces => {
      spacesByFacility[facilityId] = spaces;
      callback(spaces);
    });
  } else {
    callback(cached);
  }
};

const marker = 1000;
const decimalPlaces = 2;
const kiloBytes = marker;
const megaBytes = marker * marker;
const gigaBytes = marker * marker * marker;
export const formatBytes = bytes => {
  // return bytes if less than a KB
  if (bytes < kiloBytes) return bytes + ' bytes';
  // return KB if less than a MB
  else if (bytes < megaBytes)
    return (bytes / kiloBytes).toFixed(decimalPlaces) + ' KB';
  // return MB if less than a GB
  else if (bytes < gigaBytes)
    return (bytes / megaBytes).toFixed(decimalPlaces) + ' MB';
  // return GB if less than a TB
  else return (bytes / gigaBytes).toFixed(decimalPlaces) + ' GB';
};
