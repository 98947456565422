import './Login.css';
import { Button, TextInput } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { User, Key } from 'tabler-icons-react';
import { SharedContext } from '../App';
import { useEffect, useState, useContext, useCallback } from 'react';
import { post } from 'axios';
import { getApiUri } from '../Utilities';
import { useNavigate } from 'react-router-dom';
import { checkToken } from '../hooks/useAuthenticationCheck';

const identifierErrorMessage = 'You need to enter the username.';
const passwordErrorMessage = 'You need to enter the username.';

const Login = () => {
  const { setAppTitle, setRoutes } = useContext(SharedContext);

  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [verifyingToken, setVerifyingToken] = useState(false);
  const [identifierError, setIdentifierError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const navigate = useNavigate();

  const onGotUserData = useCallback(
    response => {
      setAppTitle(response.data.appTitle || '');
      setRoutes(response.data.routes || []);
      navigate(response.data.redirectUrl);
    },
    [setAppTitle, setRoutes, navigate]
  );

  useEffect(() => {
    setVerifyingToken(true);
    checkToken(
      response => {
        onGotUserData(response);
        setVerifyingToken(false);
      },
      () => setVerifyingToken(false)
    );
  }, [onGotUserData]);

  const submit = async e => {
    e.preventDefault();

    if (!identifier) {
      setIdentifierError(identifierErrorMessage);
      return;
    }

    if (!password) {
      setPasswordError(passwordErrorMessage);
      return;
    }

    post(getApiUri('users/login'), {
      identifier: identifier,
      password: password,
    })
      .then(response => {
        localStorage.setItem('token', response.data.token);
        onGotUserData(response);
      })
      .catch(() => {
        showNotification({
          title: 'Failed to log in',
          message: 'Invalid username or password',
          color: 'red',
        });
      });
  };

  if (verifyingToken) {
    return <div>Verifying . . .</div>;
  }

  return (
    <form className='Login' onSubmit={submit}>
      <TextInput
        icon={<User />}
        label='Username'
        required
        placeholder='Username'
        error={identifierError}
        name='identifier'
        value={identifier}
        onChange={e => {
          const { value } = e.target;
          setIdentifier(value);
          setIdentifierError(value ? null : identifierErrorMessage);
        }}
      />
      <TextInput
        icon={<Key />}
        type='password'
        label='Password'
        required
        placeholder='Password'
        error={passwordError}
        name='password'
        value={password}
        onChange={e => {
          const { value } = e.target;
          setPassword(value);
          setPasswordError(value ? null : passwordErrorMessage);
        }}
      />
      <Button type='submit' fullWidth style={{ marginTop: 15 }}>
        Log in
      </Button>
    </form>
  );
};

export default Login;
