import './PdfManagement.css';
import { useEffect, useRef, useState, useContext } from 'react';
import {
  Button,
  ActionIcon,
  ThemeIcon,
  Stack,
  Group,
  Text,
  Modal,
  List,
  Title,
  TextInput,
  Select,
  Alert,
  Image,
} from '@mantine/core';
import { Dropzone, PDF_MIME_TYPE } from '@mantine/dropzone';
import { showNotification } from '@mantine/notifications';
import {
  Edit,
  X,
  Eye,
  FileText,
  Search,
  Link,
  Clipboard,
  AlertCircle,
  ArrowsSort,
  Bed,
  Hierarchy,
} from 'tabler-icons-react';
import {
  loadDataForFacility,
  loadSpaceTypes,
  addPdfByData,
  addPdfByLocation,
  updatePdfBasicData,
  updatePdfByData,
  updatePdfByLocation,
  deletePdf,
  createNewPdf,
} from '../requests/PdfManagementRequests';
import { loadFacilities } from '../requests/SharedRequests';
import {
  getItemDisplayValue,
  readFileAsBase64String,
  formatBytes,
  updateFormFieldFromEvent,
  extractNumberFromString,
} from '../Utilities';
import ImageUploadIcon from '../components/ImageUploadIcon';
import { SharedContext } from '../App';
import useAuthenticationCheck from '../hooks/useAuthenticationCheck';

const updateSucceededMessage = 'PDF uspješno ažuriran!';
const addSucceededMessage = 'PDF uspješno dodan!';
const locationErrorMessage =
  'Potrebno je ili navesti lokaciju s koje se treba PDF preuzeti ili odabrati PDF dokument s vašeg računala.';
const pdfNameErrorMessage = 'Potreban je naziv na barem jednom jeziku.';

const initialFormState = {
  localizedValues: {},
  categoryId: '',
  spaceType: '',
  order: '',
  location: '',
  data: '',
};

const pdfToFormData = pdf => {
  const localizedValues = {};
  if (pdf.localizedValues) {
    pdf.localizedValues.forEach(localizedValue => {
      localizedValues[localizedValue.culture] = localizedValue.value;
    });
  }
  return {
    id: pdf.id,
    localizedValues: localizedValues,
    categoryId: `${pdf.parentId}`,
    spaceType: pdf.spaceType || '',
    order: formatOrder(`${pdf.order}`),
  };
};

const formatOrder = inputValue => (inputValue < 1 ? '' : inputValue);

const equalityKeys = [
  'id',
  'categoryId',
  'order',
  'spaceType',
  'localizedValues',
];
let pdfToUpdate;

const PdfManagement = () => {
  useAuthenticationCheck();

  const { setPageAlignItems } = useContext(SharedContext);

  const [facilities, setFacilities] = useState(null);
  const [pdfs, setPdfs] = useState(null);
  const [categories, setCategories] = useState(null);
  const [languages, setLanguages] = useState(null);
  const [spaceTypes, setSpaceTypes] = useState(null);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [isAddingPdf, setIsAddingPdf] = useState(false);
  const [isEditingPdf, setIsEditingPdf] = useState(false);
  const [formValues, setFormValues] = useState(initialFormState);
  const [showRemark, setShowRemark] = useState(true);
  const [pickedFile, setPickedFile] = useState(null);
  const [pdfNameError, setPdfNameError] = useState(null);
  const [menuError, setMenuError] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const [pdfToDelete, setPdfToDelete] = useState(null);

  const dropzoneReference = useRef();

  const resetForm = () => {
    setPickedFile(null);
    setFormValues(initialFormState);
    setLocationError(null);
    setMenuError(null);
  };

  const selectFacility = facility => {
    if (facility && selectedFacility && selectedFacility.id !== facility.id) {
      loadDataForFacilityAndSetState(facility.id);
    }
    setSelectedFacility(facility);
  };

  const loadDataForFacilityAndSetState = facilityId =>
    loadDataForFacility(
      facilityId,
      (pdfs, categories, languages, spaceTypes) => {
        setPdfs(pdfs);
        setCategories(categories);
        setLanguages(languages);
        setSpaceTypes(spaceTypes);
      }
    );

  const updatePdfFileContent = (localizedValues, fileName) => {
    if (formValues.location) {
      updatePdfByLocation(
        pdfToUpdate.id,
        formValues.location,
        fileName,
        localizedValues,
        onPdfUpdated
      );
    } else {
      updatePdfByData(
        pdfToUpdate.id,
        formValues.data,
        fileName,
        localizedValues,
        onPdfUpdated
      );
    }
  };

  const updatePdfCollection = (newPdf, pdfId, updatedPdf) => {
    const newPdfsObject = {};
    const add = !!newPdf; // we add if there is something in newPdf
    let added = false;

    Object.keys(pdfs).forEach(spaceType => {
      const existingCollection = pdfs[spaceType];

      // Add
      if (add) {
        if (spaceType === newPdf.spaceType) {
          existingCollection.push(newPdf);
          added = true;
        }
        newPdfsObject[spaceType] = existingCollection;
      }

      // Update, delete
      else {
        const newCollection = [];
        existingCollection.forEach(pdf => {
          // If we found target id, we either update or delete here
          if (pdf.id === pdfId) {
            // If there is something in this parameter, update needed
            if (updatedPdf) {
              newCollection.push(updatedPdf);
            }
            // Else, delete (just don't push into new collection)
          } else {
            // Nothing interesting for update or delete, just push
            newCollection.push(pdf);
          }
        });
        newPdfsObject[spaceType] = newCollection;
      }
    });

    if (add && !added) {
      if (newPdf.spaceType) {
        newPdfsObject[newPdf.spaceType] = [newPdf];
      } else {
        newPdfsObject['Bez tipa sobe'].push(newPdf);
      }
    }

    setPdfs(newPdfsObject);
  }; // ENDOF: updatePdfCollection

  const onPdfAdded = (id, location, localizedValues) => {
    updatePdfCollection(
      createNewPdf(id, location, localizedValues, formValues)
    );
    showNotification({
      color: 'green',
      message: addSucceededMessage,
    });
    resetForm();
    setIsAddingPdf(false);
  };

  const onPdfUpdated = updatedPdf => {
    updatePdfCollection(null, updatedPdf.id, updatedPdf);
    showNotification({
      color: 'green',
      message: updateSucceededMessage,
    });
    resetForm();
    setIsEditingPdf(false);
  };

  const areLocalizedValuesCorrect = () => {
    const keys = Object.keys(formValues.localizedValues);
    if (!keys.length) {
      setPdfNameError(pdfNameErrorMessage);
      return false;
    }
    if (keys.every(key => !formValues.localizedValues[key])) {
      setPdfNameError(pdfNameErrorMessage);
      return false;
    }

    setPdfNameError(null);
    return true;
  };

  useEffect(() => {
    setPageAlignItems('flex-start');

    if (spaceTypes == null) {
      loadSpaceTypes(loadedSpaceTypes => {
        setSpaceTypes(loadedSpaceTypes);
      });
    }

    if (facilities == null) {
      loadFacilities(true, loadedFacilities => {
        setFacilities(loadedFacilities);
      });
    }
    if (pdfs == null && selectedFacility) {
      loadDataForFacilityAndSetState(selectedFacility.id);
    }

    return () => setPageAlignItems('center');
  }, [spaceTypes, pdfs, facilities, selectedFacility, setPageAlignItems]);

  // ===================
  // Add / Edit PDF form
  // ===================
  if (isAddingPdf || isEditingPdf) {
    return (
      <div className='Management'>
        <Stack>
          <Button
            variant='outline'
            onClick={() => {
              resetForm();
              if (isAddingPdf) setIsAddingPdf(false);
              else setIsEditingPdf(false);
            }}
            style={{ width: 100 }}
          >
            Odustani
          </Button>
          <form
            onSubmit={e => {
              e.preventDefault();

              const isPdfDataSet = formValues.data || formValues.location;

              if (!areLocalizedValuesCorrect()) {
                return;
              }

              Object.keys(formValues.localizedValues).forEach(culture => {
                if (!formValues.localizedValues[culture]) {
                  delete formValues.localizedValues[culture];
                }
              });

              const localizedValues = languages.map(language => {
                const inputValue = formValues.localizedValues[language.culture];
                return {
                  languageId: language.id,
                  culture: language.culture,
                  value: inputValue || null,
                };
              });

              const fileName = pickedFile?.name || null;

              // ==============
              // Adding the PDF
              // ==============
              if (isAddingPdf) {
                if (!formValues.categoryId) {
                  setMenuError(
                    'Morate odabrati pod koji meni treba staviti ovaj PDF.'
                  );
                  return;
                }
                if (!isPdfDataSet) {
                  setLocationError(locationErrorMessage);
                  return;
                }
                if (formValues.location) {
                  addPdfByLocation(
                    formValues.categoryId,
                    formValues.spaceType || null,
                    formValues.order || null,
                    fileName,
                    formValues.location,
                    localizedValues,
                    (id, location) => onPdfAdded(id, location, localizedValues)
                  );
                } else {
                  addPdfByData(
                    formValues.categoryId,
                    formValues.spaceType || null,
                    formValues.order || null,
                    fileName,
                    formValues.data,
                    localizedValues,
                    (id, location) => onPdfAdded(id, location, localizedValues)
                  );
                }
              } else {
                // =========================
                // Updating the existing PDF
                // =========================

                const updateBasicData = !equalityKeys.every(
                  key =>
                    JSON.stringify(formValues[key]) ===
                    JSON.stringify(pdfToUpdate[key])
                );

                if (updateBasicData) {
                  updatePdfBasicData(
                    pdfToUpdate.id,
                    formValues.categoryId,
                    formValues.spaceType || null,
                    formValues.order || null,
                    localizedValues,
                    updatedPdf => {
                      if (!isPdfDataSet) {
                        onPdfUpdated(updatedPdf);
                        return;
                      }

                      updatePdfFileContent(localizedValues, fileName);
                    }
                  );
                } else {
                  // No basic data update, means only file content needs to change
                  if (!isPdfDataSet) {
                    setLocationError(locationErrorMessage);
                    return;
                  }

                  updatePdfFileContent(localizedValues, fileName);
                }
              }
            }}
          >
            <Stack spacing='xs'>
              <Group spacing='xs'>
                <Text size='sm'>
                  Naziv dokumenta{' '}
                  <span
                    color='red'
                    style={{ fontWeight: 'normal', fontSize: 15 }}
                  >
                    *
                  </span>
                </Text>
              </Group>
              {languages.map(language => (
                <TextInput
                  name={`${language.culture}`}
                  key={language.id}
                  icon={<Image src={language.image} width={20} />}
                  placeholder={`Naziv dokumenta za jezik '${language.englishName}'`}
                  error={pdfNameError}
                  value={formValues.localizedValues[language.culture]}
                  onChange={e => {
                    setPdfNameError(null);
                    setFormValues({
                      ...formValues,
                      localizedValues: {
                        ...formValues.localizedValues,
                        [language.culture]: e.target.value,
                      },
                    });
                  }}
                />
              ))}
            </Stack>
            <Select
              label='Meni'
              searchable
              clearable
              icon={<Hierarchy />}
              nothingFound='Nema menija s tim imenom'
              required
              style={{ marginTop: 10 }}
              error={menuError}
              placeholder='Odaberite pod koji meni treba staviti PDF'
              data={categories.map(category => {
                return {
                  value: `${category.id}`,
                  label: getItemDisplayValue(category),
                };
              })}
              name='categoryId'
              value={formValues.categoryId}
              onChange={e => {
                setMenuError(null);
                setFormValues({ ...formValues, categoryId: e });
              }}
            />
            <Select
              label='Tip sobe'
              searchable
              clearable
              icon={<Bed />}
              nothingFound='Nema tipa sobe s tim imenom'
              placeholder='Odaberite tip sobe'
              data={spaceTypes.map(spaceType => spaceType.name)}
              name='spaceType'
              value={formValues.spaceType}
              onChange={e => {
                setFormValues({ ...formValues, spaceType: e });
              }}
            />
            <TextInput
              label='Redoslijed'
              name='order'
              icon={<ArrowsSort />}
              placeholder='Redoslijed'
              value={formValues.order}
              onChange={e => {
                const { value } = e.target;
                const number = extractNumberFromString(value);
                const newOrder = formatOrder(number);
                if (formValues.order !== newOrder && newOrder !== '-') {
                  setFormValues({ ...formValues, order: newOrder });
                }
              }}
            />
            {showRemark && (
              <Alert
                withCloseButton
                closeButtonLabel='Ugasi napomenu'
                onClose={() => setShowRemark(false)}
                style={{ margin: '15px 0' }}
                icon={<AlertCircle size={16} />}
                title='Napomena'
              >
                Možete koristiti ili lokaciju ili datoteku. Ukoliko želite da
                aplikacija skine datoteku s neke web adrese, koristite lokaciju.
                U suprotnom, koristite prijenos datoteke s vašeg računala.
              </Alert>
            )}

            <Title order={6}>Datoteka</Title>

            {pickedFile && (
              <Text
                size='sm'
                color='dimmed'
                inline
                mt={7}
                style={{ margin: 0 }}
              >
                {`${pickedFile.name} (${formatBytes(pickedFile.size)})`}
              </Text>
            )}
            {locationError && (
              <Text
                inline
                size='sm'
                mt={7}
                style={{ margin: '5px 0', color: 'rgb(241, 73, 73)' }}
              >
                {locationError}
              </Text>
            )}
            <Dropzone
              multiple={false}
              ref={dropzoneReference}
              onDrop={files => {
                const file = files[0];
                readFileAsBase64String(file, base64Data => {
                  setFormValues({ ...formValues, data: base64Data });
                  const formattedSize = formatBytes(file.size);
                  showNotification({
                    title: 'Datoteka uspješno pročitana',
                    message: `Uspješno pročitana datoteka veličine ${formattedSize}`,
                  });
                  setPickedFile(file);
                  setLocationError(null);
                  dropzoneReference.current.firstChild.value = '';
                });
              }}
              onReject={() => {
                showNotification({
                  title: 'Datoteka odbijena',
                  message:
                    'Datoteka nije PDF ili prelazi limit od 100 megabajta',
                  color: 'red',
                });
              }}
              maxSize={100 * Math.pow(1024, 2)}
              accept={PDF_MIME_TYPE}
            >
              {status => (
                <Group
                  position='center'
                  spacing='xl'
                  style={{ pointerEvents: 'none' }}
                >
                  <ImageUploadIcon status={status} size={80} />

                  <div>
                    <Text size='xl'>Ispustite PDF dokument ovdje</Text>
                    <Text size='sm' color='dimmed' inline mt={7}>
                      Ili kliknite ovdje kako biste otvorili dijalog za biranje
                      dokumenta
                    </Text>
                  </div>
                </Group>
              )}
            </Dropzone>
            <TextInput
              icon={<Link />}
              label='Web adresa'
              placeholder='https://www.neka-domena.hr/dokumenti/175'
              error={locationError}
              name='location'
              value={formValues.location}
              style={{ marginTop: 10 }}
              onChange={e => {
                updateFormFieldFromEvent(formValues, setFormValues, e);
                setLocationError(
                  formValues.data || e.target.value
                    ? null
                    : locationErrorMessage
                );
              }}
              rightSection={
                <ActionIcon
                  onClick={async () => {
                    const location = await navigator.clipboard.readText();
                    setFormValues({
                      ...formValues,
                      location: location,
                    });
                    setLocationError(null);
                  }}
                >
                  <Clipboard />
                </ActionIcon>
              }
            />
            <Button type='submit' fullWidth style={{ marginTop: 15 }}>
              {isAddingPdf ? 'Dodaj' : 'Uredi'}
            </Button>
          </form>
        </Stack>
      </div>
    );
  }

  // ======================================
  // Picking facility and browsing its PDFs
  // ======================================
  return (
    <div className='Management'>
      {facilities && (
        <Select
          searchable
          clearable
          autoFocus={!selectedFacility}
          nothingFound='Nema kampanje s tim imenom'
          icon={<Search />}
          placeholder='Pretražite kampanje'
          maxDropdownHeight={650}
          data={facilities.map(facility => {
            return {
              value: `${facility.id}`,
              label: facility.name,
            };
          })}
          name='facilitySearch'
          onChange={e => {
            const facilityToSelect = facilities.find(
              facility => `${facility.id}` === e
            );
            selectFacility(facilityToSelect);
          }}
        />
      )}
      {selectedFacility && (
        <Title style={{ marginTop: 20 }}>{selectedFacility.name}</Title>
      )}
      {selectedFacility && (
        <Button
          fullWidth
          style={{ marginTop: 20 }}
          type='outline'
          onClick={() => {
            setLocationError(null);
            setMenuError(null);
            setFormValues(initialFormState);
            setIsAddingPdf(true);
            setShowRemark(true);
          }}
        >
          Dodaj novi PDF
        </Button>
      )}
      {pdfs && (
        <Stack>
          {Object.keys(pdfs).map(spaceType => (
            <Stack key={spaceType}>
              <Title order={2} style={{ marginTop: 15, marginBottom: -10 }}>
                {spaceType}
              </Title>

              <List spacing='xs' size='sm' center style={{ margin: '0' }}>
                {pdfs[spaceType].map(pdf => (
                  <List.Item
                    className='pdf-item'
                    key={pdf.id}
                    icon={
                      <ThemeIcon radius='xl'>
                        <FileText />
                      </ThemeIcon>
                    }
                  >
                    <Group style={{ width: '100%' }} position='apart'>
                      <Text>{getItemDisplayValue(pdf)}</Text>
                      <Group position='right' spacing='xs'>
                        <ActionIcon
                          onClick={() => window.open(pdf.value, '_blank')}
                        >
                          <Eye />
                        </ActionIcon>
                        <ActionIcon
                          onClick={() => {
                            pdfToUpdate = pdfToFormData(pdf);
                            setFormValues({ ...formValues, ...pdfToUpdate });
                            setLocationError(null);
                            setMenuError(null);
                            setIsEditingPdf(true);
                          }}
                        >
                          <Edit />
                        </ActionIcon>
                        <ActionIcon onClick={() => setPdfToDelete(pdf)}>
                          <X color='red' />
                        </ActionIcon>
                      </Group>
                    </Group>
                  </List.Item>
                ))}
              </List>
            </Stack>
          ))}
        </Stack>
      )}
      <Modal
        centered
        transition='slide-left'
        opened={!!pdfToDelete}
        onClose={() => setPdfToDelete(null)}
        title={`Jeste li sigurni da želite obrisati PDF dokument '${getItemDisplayValue(
          pdfToDelete
        )}'?`}
      >
        <Group>
          <Button
            onClick={() => {
              deletePdf(pdfToDelete.id, () => {
                updatePdfCollection(null, pdfToDelete.id, null);
                setPdfToDelete(null);
              });
            }}
          >
            Da
          </Button>
          <Button variant='outline' onClick={() => setPdfToDelete(null)}>
            Ne
          </Button>
        </Group>
      </Modal>
    </div>
  );
};

export default PdfManagement;
