import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import { MantineProvider } from '@mantine/core';
import {
  NotificationsProvider,
  showNotification,
} from '@mantine/notifications';
import axios from 'axios';

axios.interceptors.response.use(null, error => {
  if (error && error.response) {
    const { status, statusText, data } = error.response;
    const errorMessage = `[${status}] ${statusText}: ${JSON.stringify(data)}`;
    console.error(errorMessage);
    if (status === 401 || status === 403) {
      if (!window.location.href.endsWith('/login')) {
        // window.location.href = '/login';
        showNotification({
          title: 'Not allowed',
          message: "You aren't allowed to access the resource",
          color: 'red',
        });
        return;
      }
    }
  } else {
    console.error(error);
  }
  showNotification({
    title: 'An error occurred',
    message: 'Please try again in a moment',
    color: 'red',
  });
  return Promise.reject(error);
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MantineProvider>
      <NotificationsProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </NotificationsProvider>
    </MantineProvider>
  </React.StrictMode>
);
