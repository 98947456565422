import { put } from 'axios';
import { getApiConfig, getApiUri } from '../Utilities';

export const updateFacilities = (updatedFacilities, callback) => {
  put(
    getApiUri('facilities'),
    {
      facilities: updatedFacilities,
    },
    getApiConfig()
  ).then(response => {
    callback(response.data.numberOfChanges);
  });
};
