import './SpaceManagement.css';
import {
  ActionIcon,
  Group,
  Select,
  Table,
  Title,
  Tooltip,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Edit, FileText, Nfc, Qrcode, Search, X } from 'tabler-icons-react';
import { SharedContext } from '../App';
import LinkWithIcon from '../components/LinkWithIcon';
import useAuthenticationCheck from '../hooks/useAuthenticationCheck';
import { loadFacilities } from '../requests/SharedRequests';
import { getLinkForIdentifier, getSpacesForFacility } from '../Utilities';

const SpaceManagement = () => {
  useAuthenticationCheck();

  const navigate = useNavigate();
  const { setPageAlignItems } = useContext(SharedContext);

  const [facilities, setFacilities] = useState(null);
  const [spaces, setSpaces] = useState(null);

  const [selectedFacility, setSelectedFacility] = useState(null);

  const selectFacility = facility => {
    if (
      facility &&
      (!selectedFacility || selectedFacility.id !== facility.id)
    ) {
      getSpacesForFacility(facility.id, spacesForFacility => {
        setSpaces(spacesForFacility);
      });
    }
    setSelectedFacility(facility);
  };

  useEffect(() => {
    setPageAlignItems('flex-start');

    if (facilities == null) {
      loadFacilities(true, loadedFacilities => {
        setFacilities(loadedFacilities);
      });
    }

    return () => setPageAlignItems('center');
  }, [facilities, setPageAlignItems]);

  return (
    <div className='SpaceManagement'>
      {facilities && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Select
            searchable
            clearable
            autoFocus={!selectedFacility}
            nothingFound='Nema kampanje s tim imenom'
            icon={<Search />}
            placeholder='Pretražite kampanje'
            maxDropdownHeight={650}
            style={{ width: '100%' }}
            data={facilities.map(facility => {
              return {
                value: `${facility.id}`,
                label: facility.name,
              };
            })}
            name='facilitySearch'
            onChange={e => {
              const facilityToSelect = facilities.find(
                facility => `${facility.id}` === e
              );
              selectFacility(facilityToSelect);
            }}
          />

          {/* Edit */}
          <Tooltip label='Uredi kampanje'>
            <ActionIcon
              onClick={() => navigate('/facility-management')}
              style={{ marginLeft: 10 }}
              size='md'
            >
              <Edit />
            </ActionIcon>
          </Tooltip>
        </div>
      )}
      {selectedFacility && (
        <Title style={{ marginTop: 20 }}>{selectedFacility.name}</Title>
      )}
      {spaces && (
        <Table striped highlightOnHover verticalSpacing='sm'>
          <thead>
            <tr>
              <th>Labela</th>
              <th>Tip sobe</th>
              <th>QR</th>
              <th>NFC</th>
              <th>Akcije</th>
            </tr>
          </thead>
          <tbody>
            {spaces?.map(space => (
              <tr key={space.id}>
                <td>{space.name}</td>
                <td>{space.type}</td>
                <td>
                  {space.qr ? (
                    <LinkWithIcon
                      icon={<Qrcode />}
                      text={space.qr}
                      url={getLinkForIdentifier(space.qr)}
                      openInNewTab
                    />
                  ) : (
                    '-'
                  )}
                </td>
                <td>
                  {space.nfc ? (
                    <LinkWithIcon
                      icon={<Nfc />}
                      text={space.nfc}
                      url={getLinkForIdentifier(space.nfc)}
                      openInNewTab
                    />
                  ) : (
                    '-'
                  )}
                </td>
                <td>
                  <Group spacing='xs'>
                    {/* PDF */}
                    <Tooltip
                      label={`Uredi PDF dokumente za tip sobe '${
                        space.type || 'Bez tipa sobe'
                      }'`}
                    >
                      <ActionIcon
                        onClick={() =>
                          showNotification({
                            message:
                              'trenutno nije podržano... zasad se trebaju uređivati pomoću PDF taba',
                            color: 'yellow',
                          })
                        }
                      >
                        <FileText />
                      </ActionIcon>
                    </Tooltip>

                    {/* Edit */}
                    <Tooltip
                      label={`Uredi PDF dokumente za tip sobe '${
                        space.type || 'Bez tipa sobe'
                      }'`}
                    >
                      <ActionIcon
                        onClick={() =>
                          showNotification({
                            message:
                              'uređivanje labele trenutno nije podržano...',
                            color: 'yellow',
                          })
                        }
                      >
                        <Edit />
                      </ActionIcon>
                    </Tooltip>

                    {/* Delete */}
                    <Tooltip
                      label={`Uredi PDF dokumente za tip sobe '${
                        space.type || 'Bez tipa sobe'
                      }'`}
                    >
                      <ActionIcon
                        color='red'
                        onClick={() =>
                          showNotification({
                            message:
                              'brisanje labele trenutno nije podržano...',
                            color: 'yellow',
                          })
                        }
                      >
                        <X />
                      </ActionIcon>
                    </Tooltip>
                  </Group>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default SpaceManagement;
