import './OrderGroup.css';
import Order from './Order';
import { Badge } from '@mantine/core';

const OrderGroup = ({ orders, setOrderStatus, orderStatusToSet }) => {
  if (!orders?.length) {
    return (
      <Badge color='green' size='lg' variant='outline'>
        There are no orders here
      </Badge>
    );
  }
  return (
    <div className='OrderGroup'>
      {orders.map(order => (
        <Order
          key={order.id}
          {...order}
          setOrderStatus={setOrderStatus}
          orderStatusToSet={orderStatusToSet}
        />
      ))}
    </div>
  );
};

export default OrderGroup;
